import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Nominee } from './nominee.models';



@Injectable({
  providedIn: 'root',
})

export class NomineeService {

  constructor(private http: HttpClient) { }
  createNominee(nominee:Nominee){return this.http.post<Nominee>(`${environment.ip}/posync/nominee/`,nominee);}
  updateNominee(nominee:Nominee){return this.http.put<Nominee>(`${environment.ip}/posync/nominee/`,nominee);}
  getNominee(){return this.http.get<Nominee>(`${environment.ip}/posync/nomiee`);}
}
