export class Nominee {
    phone_number:string="";
    name:string="";
    date_of_birth:string="";
    relation:string="";
    
    constructor(){
        this.phone_number="";
        this.name="";
        this.date_of_birth="";
        this.relation="";
    }
  }