import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/dashboard/dashboard.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';


@Component({
  selector: 'app-base-settings',
  templateUrl: './base-settings.component.html',
  styleUrls: ['./base-settings.component.css']
})
export class BaseSettingsComponent implements OnInit {

  constructor(private location:Location,
    private service: DashboardService,private router : Router) { }

  ngOnInit(): void {
  }

  back(){
    this.location.back();
  }
  redirectTo(path:string){
    console.log(path);
    this.router.navigate([path]);
  }
}
