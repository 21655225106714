import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kyc',
  templateUrl: './kyc.component.html',
  styleUrls: ['./kyc.component.css']
})
export class KycComponent implements OnInit {

  constructor() { }
  PAGE_NAME="Kyc Verification";

  ngOnInit(): void {
  }

}
