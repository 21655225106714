<div class="container pt-3">
    <div class="card">
      <!-- <div class="card-title bold">My Account</div> -->
      <div class="card-body">
          <form #login="ngForm" >
              <div class='w-100 mt form-group'>
                  <div class='w-100 mb-3 form-group'>
                      <div class="regular-black">Phone</div>
                      <input [(ngModel)]="nominee.phone_number" class="form-control custom-input w-100">
                    </div>
              </div>
              <div class='w-100 form-group'>
                  <div class='w-100 mb-3 form-group'>
                      <div class="regular-black">Name</div>
                      <input  [(ngModel)]="nominee.name" name="name" type='text' class="form-control custom-input w-100">
                    </div>
              </div>
              <div class='w-100 form-group mb-3'>
                <div class="regular-black">DOB</div>
                <input  matInput name="dob" [(ngModel)]="nominee.date_of_birth"  type="date" class="custom-input w-100 form-control">
            </div>
              <div class='w-100 form-group mb-3'>
                  <div class="regular-black">Relation</div>
                  <select  [(ngModel)]="nominee.relation" name="gender" mat-ripple class="w-100 custom-select mb-3" placeholder="choose"  >
                    <option value='Mother'>Mother</option>
                    <option value="Father">Father</option>
                    <option value="Sister">Sister</option>
                    <option value="Spouse">Spouse</option>
                </select>
              </div>
       
              <input  type="submit" class='custom-btn gold-bg semibold-white mt-4  w-100' (click)="createNominee(nominee)" mat-ripple placeholder="Save">
  
          </form>
      </div>
  </div>
  

  
  
  </div>