import { Component, OnInit } from '@angular/core';
import { MyAccountComponent } from 'src/app/my-account/my-account.component';
import { NomineeService } from './nominee.service';
import { Nominee } from './nominee.models';

@Component({
  selector: 'app-nominee',
  templateUrl: './nominee.component.html',
  styleUrls: ['./nominee.component.css','../../my-account/my-account.component.css'],
  providers:[NomineeService]
})
export class NomineeComponent implements OnInit {

  constructor(private nomineeService:NomineeService) { }
  PAGE_NAME="Nominee";
  nominee:Nominee= new Nominee;

  ngOnInit(): void {
  }

  createNominee(nominee:Nominee) {
      this.nomineeService.createNominee(nominee=nominee).subscribe(
        (resp:any)=>{console.log(resp)},
        (err)=>{console.log(err)})
  }

}
