
<div class="custom-nav">
    <button class="mr-2" mat-icon-button (click)="back()">
        <mat-icon>arrow_back</mat-icon>
      </button>
    <span class="bold-small">Settings</span>
  </div>
  
  
  <div class="container">
    <!-- account -->
    <div class="account">
      <div class="section-title">Account</div>
      <div class="account-list">
        <div class="account-item" routerLink="/page/my-account">
          <div><img src="assets/icons/settings-profile.svg">Profile</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-kyc.svg">KYC</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item" routerLink="/page/add-nominee">
          <div><img src="assets/icons/settings-nominee.svg">My Nominee</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-billing.svg">Billing Address</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-language.svg">Language Preference</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
    <!-- My Transaction -->
    <div class="my-transaction">
      <div class="section-title">My Transaction</div>
      <div class="account-list">
        <div class="account-item">
          <div routerLink="/dashboard/savings"><img src="assets/icons/settings-jewellery-plans.svg">Jewellery Plans</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div  routerLink="/dashboard/user-vault" class="account-item"  >
          <div><img src="assets/icons/settings-digital-coin.svg">Digital Coins</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item" routerLink="/dashboard/view-bookings">
          <div ><img src="assets/icons/settings-advance-booking.svg">Advance Bookings</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-orders.svg">Customization orders</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-jewellery-purchase.svg">Jewellery Purchase</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <div class="account-item" routerLink="/dashboard/transaction">
          <div><img src="assets/icons/settings-jewellery-purchase.svg">Transaction</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
    <!-- My Jewellery worth -->
    <div class="my-jewellery-worth">
      <div class="section-title">My Jewellery worth</div>
      <div class="account-list">
        <div class="account-item">
          <div><img src="assets/icons/settings-track-net-worth.svg">Track Net worth</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
    <!-- Security -->
    <div class="security">
      <div class="section-title">Security</div>
      <div class="account-list">
        <div class="account-item" routerLink="/page/security">
          <div><img src="assets/icons/settings-lock.svg">Set App lock and password</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
    <!-- My Preference  -->
    <div class="my-preference">
      <div class="section-title">My Preferences</div>
      <div class="account-list">
        <div class="account-item">
          <div><img src="assets/icons/settings-language.svg">Language</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-design-preference.svg">Design preferences</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
      </div>
    </div>
    <!-- Share App -->
    <div class="share-app">
      <div class="section-title">Share App</div>
      <div class="account-list">
        <div class="account-item">
          <div><img src="assets/icons/settings-share.svg">Share app</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
        <hr>
        <div class="account-item">
          <div><img src="assets/icons/settings-reward.svg">My Rewards</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
    <!-- Support -->
    <div class="support mb-3">
      <div class="section-title">Support</div>
      <div class="account-list">
        <div class="account-item">
          <div><img src="assets/icons/settings-support.svg">Support</div>
          <div><img src="assets/icons/settings-right-arrow.svg"></div>
        </div>
      </div>
    </div>
  </div>