// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  version:"Vt 0.0.54",

  production: false,
  is_analytics_enabled: true, // Do not change this value
  // ip: "https://siprelease.1ounce.in",
  // ip: "https://sipserver.1ounce.in",

  //test server
  // ip:"https://webstore.1ounce.in",
  sub_domain: "sip",
  ip:"https://sipserver.1ounce.in",
  razorpay_key: "rzp_test_hjhQzK4IMtyyJl",
  // google_analytics_measurement: "G-LPLL6HKJZE",

  tutorial_link: "https://www.youtube.com/watch?v=",
  how_to_banner: "https://e-jewellery-actual-test.s3.amazonaws.com/media/documents/normal/Group_125466673_bxOdAfF.png",

  firebase: {
    apiKey: "AIzaSyBnBx5AbbwVG1hF3s8KYl-s7lbKtQ0WDbo",
    authDomain: "jsip-test.firebaseapp.com",
    projectId: "jsip-test",
    storageBucket: "jsip-test.appspot.com",
    messagingSenderId: "287608336564",
    appId: "1:287608336564:web:5e7be25b3a7b445f4027e1",
    measurementId: "G-D4T96ZMNBC"
  },

  google_analytics_measurement: "G-LPLL6HKJZE",

//   firebase: {
//     apiKey: "AIzaSyBEPL9IWZ1orximKdaLAfZSu7c8qTHGseo",
//     authDomain: "jewellerysip.firebaseapp.com",
//     projectId: "jewellerysip",
//     storageBucket: "jewellerysip.appspot.com",
//     messagingSenderId: "224601867964",
//     appId: "1:224601867964:web:84cb38d96d68cc2a9632b9",
//     measurementId: "G-L8CJDDWC0F"
//   },
  cashfree_url:"https://sdk.cashfree.com/js/ui/2.0.0/cashfree.sandbox.js"

}
  /*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
