<div class="container" style="overflow-y:scroll">
  <div class="card mt-4"  >
    <!-- <button class="mr-2" mat-icon-button (click)="back()">
      <mat-icon>arrow_back</mat-icon>
    </button> -->
    <div class="card-body mt-8 p-5" >
        <div class="center bold mb-4 center">Welcome !</div>
        <div class="center">
          <img src="assets/icons/login-phone.svg">
        </div>
        <div class="center bold-small mt-3">Enter phone number to get started!</div>
        <form>

          <div class="mt-3">
            <div class="w-100">
              <label for="phone" class="semibold">Phone Number</label>
              <span *ngIf="ui_show_phone_edit" style="float:right;" mat-ripple (click)="editPhone()" class="regular-10-blue">Edit</span>

            </div>
                <div class="input-group">
                    <input [disabled]="ui_phone_input_disabled" [(ngModel)]="mobile_number" type="tel" id="bank_id"  class="custom-input form-control w-80" maxlength="20"   (input)="onPhoneChange($event)" name="mobile">

                  </div>
          </div>

          <div  *ngIf="show_otp_box" class="form-row mt-4" >
            <div class="form-group col-md-11 col-lg-11 col-12">
              <label for="inputEmail4" class="button subheadingColor">OTP*</label>
                  <div class="input-group text-center" >
                    <ng-otp-input ngOtpInput  (onInputChange)="onOtpChange($event)"  [config]="{
                        length:4,
                        inputClass:'bold-small  custom-otp'
                      , allowNumbersOnly:true
                      , containerClass:'otp-container'}">

                    </ng-otp-input>
                  </div>
                </div>
          </div>

            <button  (click)="sendOtp()" *ngIf="show_otp_button" id="send_otp_btn" type="button" class="custom-btn mt-3 w-100  bold-small-white" >
              <span class="button button_text" >
                Send OTP
                <span *ngIf="show_otp_spinner" class="spinner-border spinner_in_button text-white"></span>
              </span>
            </button>

            <div *ngIf="show_otp_send_text">


              <div  class="para1 mt-3 otp_sent_label" >OTP sent <span class="subheadingColor1 clock">- Valid for <span id="countdown">{{otp_counter}}</span> sec</span></div>

              <div >
              <p *ngIf="show_resend_otp"  id="resend" class=" bold-small pl-1 " style="cursor:pointer;" (click)="resendOtp()" >Resend OTP</p>
            </div>
            <div >
              <p *ngIf="show_retry_call"  id="resend" class=" bold-small pl-1 " style="cursor:pointer;" (click)="retryCall()" >Retry Call</p>
            </div>
          </div>

            <button (click)="login()" *ngIf="show_login_button" id="login_btn" type="button" class="custom-btn mt-3 w-100  bold-small-white" >
              <span class="button button_text" >
                Login
                <span *ngIf="show_login_spinner" class="spinner-border spinner_in_button text-white"></span>
              </span>
            </button>
        </form>


    </div>
  </div>
  <div class="center" style="margin-bottom:300px">
    <div class="semibold-grey">{{VERSION_NO}}</div>
  </div>
</div>
