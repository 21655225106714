import { Component, OnInit, ViewChild } from '@angular/core';
import { CarouselComponent } from 'angular-responsive-carousel';
import { trigger, transition, style, animate } from "@angular/animations";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


interface Collection {
  id: number;
  name: string;
  image: string;
  link: string;
}
interface TopSeller {
  id: number;
  name: string;
  imageUrl: string;
  price?: number;
  category: string;
}

interface Testimonial {
  content: string;
  name: string;
  role?: string;
}
@Component({
  
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css'],
  animations: [
    trigger("fadeInOut", [
      transition(":enter", [
        style({ opacity: 0, transform: "translateY(20px)" }),
        animate("300ms ease-out", style({ opacity: 1, transform: "translateY(0)" }))
      ])
    ])
  ]
})
export class HomePageComponent implements OnInit {

  constructor(
    private modalService:NgbModal
  ) { }

  jewelryImages = [
    {
      id: 1,
      src: 'assets/images/necklace-set.jpg',
      alt: 'Diamond Necklace Set'
    },
    {
      id: 2,
      src: 'assets/images/temple-pendant.jpg',
      alt: 'Temple Style Pendant'
    },
    {
      id: 3,
      src: 'assets/images/gold-necklace.jpg',
      alt: 'Traditional Gold Necklace'
    },
    {
      id: 4,
      src: 'assets/images/gold-bangles.jpg',
      alt: 'Gold Bangles'
    },
    {
      id: 5,
      src: 'assets/images/chandbali.jpg',
      alt: 'Traditional Chandbali Earrings'
    },
    {
      id: 6,
      src: 'assets/images/jhumka.jpg',
      alt: 'Traditional Jhumka'
    }
  ];
  
  topSellers: TopSeller[] = [
    {
      id: 1,
      name: 'Diamond Necklace Set',
      imageUrl: 'assets/images/necklace1.jpg',
      category: 'Necklaces'
    },
    {
      id: 2,
      name: 'Temple Pendant',
      imageUrl: 'assets/images/pendant1.jpg',
      category: 'Pendants'
    },
    {
      id: 3,
      name: 'Gold Chain',
      imageUrl: 'assets/images/chain1.jpg',
      category: 'Chains'
    },
    {
      id: 4,
      name: 'Gold Bangles',
      imageUrl: 'assets/images/bangles1.jpg',
      category: 'Bangles'
    },
    {
      id: 5,
      name: 'Traditional Earrings',
      imageUrl: 'assets/images/earrings1.jpg',
      category: 'Earrings'
    },
    {
      id: 6,
      name: 'Temple Jhumkas',
      imageUrl: 'assets/images/jhumkas1.jpg',
      category: 'Earrings'
    }
  ];

  collections: Collection[] = [
    {
      id: 1,
      name: 'Karnphool Earrings',
      image: 'https://picsum.photos/800/600.jpg',
      link: '/collection/karnphool-earrings'
    },
    {
      id: 2,
      name: 'Maharani Necklaces',
      image: 'https://picsum.photos/800/600.jpg',
      link: '/collection/maharani-necklaces'
    },
    {
      id: 3,
      name: 'Hathband Bangles',
      image: 'https://picsum.photos/800/600.jpg',
      link: '/collection/hathband-bangles'
    },
    {
      id: 3,
      name: 'Hathband Bangles',
      image: 'https://picsum.photos/800/600.jpg',
      link: '/collection/hathband-bangles'
    },
    {
      id: 3,
      name: 'Hathband Bangles',
      image: 'https://picsum.photos/800/600.jpg',
      link: '/collection/hathband-bangles'
    } 
  ];
  images = [
    {path: 'https://picsum.photos/800/600.jpg'},
    {path: 'https://picsum.photos/800/600.jpg'},
    {path: 'https://picsum.photos/800/600.jpg'},
    {path: 'https://picsum.photos/800/600.jpg'},
  ]
  activeSlide = 0;



  // testi
  testimonials: Testimonial[] = [];
  newTestimonial: Testimonial = {
    content: "",
    name: "",
    role: ""
  };

  contentError = false;
  nameError = false;
  currentPage = 0;
  itemsPerPage = 4;

  @ViewChild('carousel') carousel!: CarouselComponent;

  ngOnInit(): void {
    this.testimonials = [
      {
        content: "This product has completely transformed our workflow. The efficiency gains are remarkable!",
        name: "John Smith",
        role: "Product Manager"
      },
      {
        content: "Outstanding service and support. The team went above and beyond our expectations.",
        name: "Sarah Johnson",
        role: "CEO"
      }
    ];
  }

  nextTestimonials() {
    if (this.currentPage < this.totalPages - 1) {
      this.currentPage++;
    }
  }

  previousTestimonials() {
    if (this.currentPage > 0) {
      this.currentPage--;
    }

  }
  openRateModel(content:any){
    this.modalService.open(content)
  }
    get totalPages(): number {
      return Math.ceil(this.testimonials.length / this.itemsPerPage);
    }
  
    get paginatedTestimonials(): Testimonial[] {
      const start = this.currentPage * this.itemsPerPage;
      return this.testimonials.slice(start, start + this.itemsPerPage);
    }
  }


