<div class="container mb-5" style="">
 
  <div class="">
  <!-- daily rates -->
   <div class="rates" (click)="openRateModel(rate_modal)">
      <div><img src="assets/icons/gold_bar.svg"></div>
      <div>Today's Gold Rate</div>
      <div>|</div>
      <div>22k/ 1kg ₹6690</div>
    </div>
   
 
    
      <!-- carousel -->
      <div class="carousel-box my-3">
        <carousel
          [images]="images"
          [cellsToShow]="1"
          [loop]="true"
          [autoplay]="true"
          [autoplayInterval]="3000"
          [dots]="true"
          [arrows]="false"
        >
        </carousel>
      </div>
      <!-- service we offer -->
      <div class="mt-5 service-block px-3">
        <div class="mb-3 head">Service We offer</div>
        <div class="service-box">
          <div class="service-icons">
            <div   routerLink="/page/start-plan">
              <div class="icon">
                <img src="assets/icons/jewellery_plan_service.svg" />
              </div>
              <div>Jewellery <br> Plan</div>
            </div>

            <div
            routerLink="/dashboard/view-bookings">
              <div class="icon">
                <img src="assets/icons/advance_booking_service.svg" />
              </div>

              <div>Advance <br> Booking</div>
            </div>
            <div routerLink="/dashboard/digital-coin">
              <div class="icon">
                <img src="assets/icons/digital_coin_service.svg" />
              </div>

              <div>Digital <br> Coin</div>
            </div>
            <div>
              <div class="icon">
                <img src="assets/icons/store_checkout_service.svg" />
              </div>

              <div>Store <br> Checkout</div>
            </div>
          </div>
        </div>
      </div>
      <!-- Shop by collection -->
       <div class="mt-4 collection-block">
        <div class="mb-3 head">Shop By Collection</div>
        <section class="shop-collection">
          <div class="collection-container">
            <div class="collection-scroll">
              <div class="collection-item" *ngFor="let collection of collections" [routerLink]="collection.link">
                <div class="collection-card">
                  <div class="image-container">
                    <img [src]="collection.image" [alt]="collection.name" loading="lazy">
                  </div>
                  <div class="collection-title">{{ collection.name }}</div>
                </div>
              </div>
            </div>
          </div>
        </section>
    
       </div>
       <!-- top sellers -->
       <div class="mt-4 top-seller-block">
        <div class="mb-3 head">Top Seller</div>

        <div class="gallery px-4">
          <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 1" class="item large">
          <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 2" class="item small">
          <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 3" class="item tall">
              <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 5" class="item small">
        
          <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 4" class="item large">
            <img src="https://picsum.photos/800/600.jpg" alt="Jewelry 6" class="item tall">
     
        </div>
       </div>
        

        <!-- testimony -->
        <div class="p-3 mt-2">
          <div class="testimonial-box">
            <!-- Input Form -->
        
            <!-- Testimonials Display -->
            <div class="row row-cols-1 row-cols-md-2 g-4" *ngIf="testimonials.length > 0">
              <div  class="col">
                <div class="card border-0 rounded-3 p-2">
                  <div class="image-text">
                    <div class="customer-image">
                      <img  src="https://picsum.photos/800/600.jpg" class="">
                    </div>
                    <div class="review">
                      <div class="position-relative">
                        <p class="">“ Sumangali Jewellery never ceases to amaze me - I love her originality and thoughtfulness “ </p>
                      </div>
                      <div class="border-bottom">
                        <p class="fw-semibold text-dark">Sunita Kausal</p>
                      </div>
                    </div>
                  </div>
                  
             
                </div>
              </div>
            </div>
        
            <!-- Navigation Icons (Right Side) -->
            <div  class="navigation-icons">
              <button (click)="previousTestimonials()" class="nav-button left" [disabled]="currentPage === 0">
                &#8592; <!-- Left arrow symbol -->
              </button>
              <button (click)="nextTestimonials()" class="nav-button right" [disabled]="currentPage >= totalPages - 1">
                &#8594; <!-- Right arrow symbol -->
              </button>
            </div>
          </div>
        </div>
        
        
        
      
  
     
    </div>

    </div>

    <ng-template #rate_modal>
      <div class="rate_modal">
        
      </div>
    </ng-template>
